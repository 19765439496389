@use "../../../tools" as *;

.package-summary {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 60px;
  margin-bottom: 60px;

  > header {
    border: 1px solid #E0E8F0;
    margin-bottom: 60px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
  }

  > main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 24px;
  }

  &__switcher {
    cursor: pointer;
    background-color: transparent;
    color: #3d4be0;
    width: 98px;
    height: 45px;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    font-family: Montserrat, sans-serif;
    font-weight: bold;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint(map-get($breakpoints, large)) {
      width: 182px;
    }

    > p {
      margin-top: 0;
      margin-bottom: 0;
    }

    &--selected {
      background-color: #3d4be0;
      color: white;
    }
  }

  &__package {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 46px;
    background-color: rgba(#D9D9D9, .12);
    opacity: .5;
    width: 90%;

    @include breakpoint(map-get($breakpoints, large)) {
      padding: 60px 71px;
      width: 425px;
    }

    &--active {
      opacity: 1;
      background-color: white;
    }

    h1 {
      font-size: 28px;
      font-family: 'Katide', sans-serif;
      font-weight: 500;
      margin-top: 0;
      margin-bottom: 0;

      @include breakpoint(map-get($breakpoints, large)) {
        font-size: 45px;
      }
    }
  }

  &__price {
    font-size: 45px;
    font-family: 'Katide', sans-serif;
    color: #3D4BE0;
    margin-bottom: 0;
    margin-top: 35px;
    line-height: 80px;

    @include breakpoint(map-get($breakpoints, large)) {
      font-size: 80px;
    }
  }

  &__payroll {
    text-align: center;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    margin-top: 17px;
  }

  &__description {
    text-align: center;
    line-height: 40px;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    margin-top: 40px;
  }
}
