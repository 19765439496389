@use "../../tools" as *;

.vehicle-calculator {

  > form {
    width: 100%;

    @include breakpoint(map-get($breakpoints, large)) {
      width: 1000px;
    }
  }
}
