@use "../../tools" as *;

.button {
  width: 170px;
  height: 48px;
  background-color: transparent;
  color: #3D4BE0;
  font-weight: bold;
  font-size: 14px;
  padding: 15px 27px;
  cursor: pointer;
  border: 1px solid #3D4BE0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  
  @include breakpoint(map-get($breakpoints, large)) {
    margin-top: 40px;
  }

  &:hover {
    background-color: rgba(61, 75, 224, .2);
  }

  &--disabled {
    border-color: rgba(#000, .05);
    background-color: rgba(#000, .05);
    color: rgba(#000, .15);
    pointer-events: none;
  }

  &--large {
    width: 198px;
  }

  &--noMarginTop {
    margin-top: 0;
  }
}
