@use "../../../tools" as *;

.summary {
  margin-top: 60px;
  width: 100%;
  padding: 40px 30px;
  background-color: rgba(#D9D9D9, .12);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 40px;

  @include breakpoint(map-get($breakpoints, large)) {
    padding: 60px;
    flex-wrap: wrap;
    width: 1000px;
    flex-direction: row;
    gap: 60px;
  }

  dl {
    min-width: 350px;
  }

  dt {
    text-align: left;
    font-size: 20px;
    font-weight: 450;
    margin-bottom: 40px;
    font-family: 'Katide', sans-serif;

    @include breakpoint(map-get($breakpoints, large)) {
      font-size: 28px;
    }
  }

  dd {
    margin: 0;
    text-align: left;
    font-size: 20px;
    font-family: Montserrat, sans-serif;

    @include breakpoint(map-get($breakpoints, large)) {
      font-size: 28px;
    }
  }
}
