@use "../../tools" as *;

.select {
  width: 100%;

  &__label {
    color: #281F24;
    font-family: Montserrat, sans-serif;
    font-weight: 400;
    font-size: 18px;
    text-align: left;
    text-transform: capitalize;
  }

  &__wrapper {
    border: 1px solid transparent;
    background-color: rgba(#D9D9D9, .12);
    border-radius: 4px;
    padding-right: 25px;

    select {
      background-color: transparent;
      padding: 12px 0 12px 16px;
      width: 100%;
      border: none;
      color: #281F24;
      outline: none;
      font-size: 14px;
      transition: color .2s;

      @include breakpoint(map-get($breakpoints, large)) {
        padding: 21px 0 21px 25px;
      }

      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #281F24;
        opacity: .87;
      }

      &::-moz-placeholder { /* Firefox 19+ */
        color: #281F24;
        opacity: .87;
      }

      &:-ms-input-placeholder { /* IE 10+ */
        color: #281F24;
        opacity: .87;
      }

      &:-moz-placeholder { /* Firefox 18- */
        color: #281F24;
        opacity: .87;
      }

      &:focus-within,
      &:active {
        outline: none;
        border: none;
      }
    }
  }

  &__error {
    display: block;
    color: #ff4d4d;
    margin-top: 8px;
  }
}
