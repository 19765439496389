@use "../../tools" as *;

.input {
  width: 100%;
  $block: &;

  &__label {
    color: #281F24;
    font-family: Montserrat, sans-serif;
    font-weight: 400;
    font-size: 18px;
    text-transform: capitalize;
  }

  &__prefix {
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: center;
    border-right: .5px solid rgba(#000000, .1);
    font-size: 10px;

    @include breakpoint(map-get($breakpoints, large)) {
      padding: 21px;
      font-size: 18px;
    }
  }

  &__wrapper {
    margin-top: 10px;
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    background-color: rgba(#D9D9D9, .12);
    border-radius: 4px;
    transition: border-color .2s;
  }

  input {
    padding: 12px 16px;
    border: none;
    font-size: 14px;
    font-weight: 400;
    color: #281F24;
    background-color: transparent;
    flex: 1 0 auto;
    transition: color .2s;

    @include breakpoint(map-get($breakpoints, large)) {
      padding: 21px 25px;
    }

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #281F24;
      opacity: .87;
    }

    &::-moz-placeholder { /* Firefox 19+ */
      color: #281F24;
      opacity: .87;
    }

    &:-ms-input-placeholder { /* IE 10+ */
      color: #281F24;
      opacity: .87;
    }

    &:-moz-placeholder { /* Firefox 18- */
      color: #281F24;
      opacity: .87;
    }

    &:focus-within,
    &:active {
      outline: none;
      border: none;
    }
  }

  &__error {
    display: block;
    color: #ff4d4d;
    margin-top: 8px;
  }
}
