@use "../../tools" as *;

.modal {
  $modal-form: &;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  opacity: 1;
  width: 100%;
  height: 105%;

  &-overlay {
    z-index: 9999;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 105%;
    background-color: rgba(#000, .25);

    #{$modal-form}-content {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.modal-layout {
  width: 80%;
  min-height: 250px;
  overflow-y: scroll;
  border-radius: 4px;
  padding: 30px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  background-color: white;
  border: none;
  top: 50px;
  $block: &;

  @include breakpoint(map-get($breakpoints, large)) {
    padding: 60px;
    width: 800px;
    top: 150px;
  }

  &--large {
    width: 80%;

    @include breakpoint(map-get($breakpoints, large)) {
      width: 90vw;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    text-align: left;

    @include breakpoint(map-get($breakpoints, large)) {
      margin-bottom: 60px;
    }

    &-title {
      font-size: 28px;
      line-height: 28px;
      font-family: Katide, sans-serif;
      font-weight: 500;
      margin: 0;

      @include breakpoint(map-get($breakpoints, large)) {
        line-height: 45px;
        font-size: 45px;
      }
    }
  }

  &__close {
    svg {
      cursor: pointer;
      transition: opacity .2s;

      &:hover {
        fill-opacity: .7;
        opacity: .7;
      }
    }
  }
}
