/// our designed width is 1600, which falls at the lower bound of `x-large`
$breakpoints: (
        small: 576,
        medium: 768,
        large: 1420,
        x-large: 1599
);

$base-font-size: 16;

///  breakpoint mixins based on breakpoint definitions, accepting content blocks
///  to be used for responsive design
@mixin breakpoint($bp-name) {
  $min-width: '';

  @if map-has-key($breakpoints, $bp-name) {
    $min-width: map-get($breakpoints, $bp-name);
  } @else if (type_of($bp-name) == number) {
    $min-width: $bp-name;
  }

  $min-width-ems: ($min-width / $base-font-size) * 1em;

  @media screen and (min-width: $min-width-ems) {
    @content;
  }
}
