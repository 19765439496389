@use "../../tools" as *;

.form-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0 auto;

  > * {
    margin-bottom: 40px;
  }

  @include breakpoint(map-get($breakpoints, large)) {
    flex-direction: row;

    > * {
      max-width: 45%;
      margin-right: 40px;
      margin-bottom: 0;
    }

    & + & {
      margin-top: 40px;
    }
  }
}
