@use "../../../tools" as *;

.package-modal {
  width: 100%;
  font-family: Montserrat, sans-serif;

  > header {
    margin-bottom: 50px;
    font-size: 12px;

    @include breakpoint(map-get($breakpoints, large)) {
      font-size: 18px;
    }
  }

  &__message {
    font-weight: bold;
  }
}
