@use "../../tools" as *;

.pricing-plans {

  > form {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;

    @include breakpoint(map-get($breakpoints, large)) {
      width: 1000px;
      margin-top: 60px;
      margin-bottom: 60px;
    }
  }
}
